import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as myGlobals from '../../../core/modal/global';
import * as alertify from 'alertifyjs';
import { TroubleTicketsDetailsService } from '../trouble-tickets-details.service';
import { PlansService } from '../../plans/plans.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../../auth/token-storage-service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-case',
  templateUrl: './add-case.component.html',
  styleUrls: ['./add-case.component.scss']
})
export class AddCaseComponent implements OnInit {
  custId: any = this.tokenStorageService.getLoggedinUserId();
  error_message = "This field is required.";
  caseReasonList:any;
  caseTypeList:any;
  priorityList :any;
  public invalidInputMessage = "Input must not contain the characters <, >, (\", \"), (', or ')"
  caseForList:any;
  originList :any;
  isdisableBtn:boolean=false;
  caseForm = new FormGroup({
    name: new FormControl({value: '', disabled: true}, [Validators.required]),    
    username: new FormControl({value: '', disabled: true}, [Validators.required]), 
    email: new FormControl({value: '', disabled: true}, [Validators.required]), 
    mobile: new FormControl({value: '', disabled: true}, [Validators.required]), 
    caseTitle: new FormControl(null, [Validators.required,malicliousInputValidator()]),
    caseType: new FormControl('Issue',[Validators.required]),
    caseReasonId: new FormControl(null,[Validators.required]),
    firstRemark: new FormControl('', [Validators.required]),
    customersId: new FormControl(this.custId),
    caseFor: new FormControl('Customer'),
    caseOrigin: new FormControl('Self Care',[Validators.required]),
    priority: new FormControl('Medium',[Validators.required])
  });

  constructor(
    private troubleTicketsDetailsService :TroubleTicketsDetailsService,
    private plansService:PlansService,
    private router: Router,
    private tokenStorageService:TokenStorageService,
    private toaster:ToastrService
    ) { }

  ngOnInit() {
    this.getSubscriberBasicDetails();
    this.getCommanlist();
  }

  validateForm() {
    if (!this.caseForm.valid) {
      this.isdisableBtn=false;
      this.toaster.error("Please fill all the details!");
      Object.keys(this.caseForm.controls).forEach(field => {
        const control = this.caseForm.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        }
      });
    }else{
      this.saveCases();
    }
  }

  resetForm(){
    this.caseForm.reset();
  }

  saveCases() {
    this.isdisableBtn=true;
    if (this.caseForm.valid === true) {
      this.troubleTicketsDetailsService.saveCases(this.caseForm.value).subscribe((result) => {
        if(result){          
          if(result.responseCode===200){
            this.toaster.success('Case has been saved successfully');
            this.resetForm();
            this.isdisableBtn=true;
            this.router.navigate(['cases']);
          }else if(result.responseCode !==200){
            this.isdisableBtn=false;
            this.toaster.error(result.responseMessage);
          }
        }
       },err => {
         this.toaster.error(err.error.status + " " + err.error.ERROR);
         this.isdisableBtn=false;
       });
    }
  }

  public getCommanlist() {
    this.troubleTicketsDetailsService.getAllcaseReason().subscribe((res) => {
      this.caseReasonList = res.dataList;
    });
    this.troubleTicketsDetailsService.getCommonListType('caseType').subscribe((res) => {
      this.caseTypeList = res.dataList;
    });
    // this.troubleTicketsDetailsService.getCommonListType('priority').subscribe((res) => {
    //   this.priorityList = res.dataList;
    // });
    // this.troubleTicketsDetailsService.getCommonListType('caseFor').subscribe((res) => {
    //   this.caseForList = res.dataList;
    // });
    // this.troubleTicketsDetailsService.getCommonListType('origin').subscribe((res) => {
    //   this.originList = res.dataList;
    // });
  }

  getSubscriberBasicDetails() {
    let subscriber_id = this.custId;
    this.plansService.getSubscriberBasicDetails(subscriber_id).subscribe((result) => {
      if (result.data) {
        //console.log("case",result.data); 
        this.caseForm.get('name').setValue(result.data.name);
        this.caseForm.get('email').setValue(result.data.email);
        this.caseForm.get('mobile').setValue(result.data.mobile);
        this.caseForm.get('username').setValue(result.data.userName);
      }
    });
  }
  backToViewList(){
    this.router.navigate(['cases']);
  }  
}
export function malicliousInputValidator(): ValidatorFn {
  const forbiddenStrings = ['<', '>', '("', '")', "('", "')"];
  return (control: AbstractControl): ValidationErrors | null => {
    const inputValue: string = control.value;
    if (inputValue) {
      for (const str of forbiddenStrings) {
        if (inputValue.includes(str)) {
          return { invalidCode: true };
        }
      }
    }
    return null;
  };
}