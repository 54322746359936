const neoGridDefaultSettings = {
  showAdvanceSearch: false,
  showFooter: false,
  showPagination: false,
  tableId: '',
  perPage: 5,
  pageSizeOptions: [5, 10, 15, 20, 50, 100],
  sort: true,
  defaultSortCol: '',
  defaultSortDirection: 'asc',
  showFilter: true,
  columns: [],
  deleteMsgBoxDetail: '',
  deleteMsgBoxTitle: 'Are you sure you want to delete?',
  enableExcelExport: true,
  excelExportUrl: '',
  enablePdfExport: true,
  pdfExportUrl: '',
  enableMultiDelete: false,
  showMultiDeleteConfirmBox: true,
};
export { neoGridDefaultSettings };
