import { Routes } from '@angular/router';
import { ErrorComponent } from './pages/error/error.component';
import { AppGuard } from './app.guard';
import { PaymentMidpageComponent } from './components/payment-midpage/payment-midpage.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { PaymentLinkComponent } from './components/payment-link/payment-link.component';
import { CustomerBasicDetailsComponent } from './components/customer-basic-details/customer-basic-details.component';
import { VerificationComponent } from './pages/reset-password/verification/verification.component';
import { ThankyouPageComponent } from './components/thankyou-page/thankyou-page.component';
import { CustPaymentComponent } from './pages/cust-payment/cust-payment/cust-payment.component';
import { SessionExpireComponent } from './components/session-expire/session-expire.component';

export const ROUTES: Routes = [
  {
    path: '', canActivate: [AppGuard], loadChildren: './layout/layout.module#LayoutModule'
  },
  { path: 'recharge/payment-status', component: PaymentStatusComponent },
  {
    path: 'customer-basic-details/:id', component: CustomerBasicDetailsComponent, pathMatch: 'full'
  },
  {
    path: 'customer-basic-details', component: CustomerBasicDetailsComponent, pathMatch: 'full',
  },
  {
    path: 'customer-basic-details-verify-customer/:id', component: VerificationComponent, pathMatch: 'full'
  },
  {
    path: 'customer-basic-details-verify-customer', component: VerificationComponent, pathMatch: 'full',
  },
  {
    path: 'thank-you-page', component: ThankyouPageComponent
  },
  {
    path: 'session-expire', component: SessionExpireComponent
  },
  {
    path: 'redirecttopayment/:id', component: PaymentMidpageComponent
  },
  {
    path: 'pay/:user', component: PaymentLinkComponent
  },
  {
    path: 'login', loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: 'kyc-details/:id', loadChildren: './pages/kyc-details/kyc-details.module#KycDetailsModule'
  },
  {
    path: 'kyc-details-verify-customer/:id',component:VerificationComponent
    
  },
  {
    path: 'cust-payment/:id', component:CustPaymentComponent 
  },
  {
    path: 'cust-payment-verify-customer/:id', component:VerificationComponent 
  },
  {
    path: 'register', loadChildren: './pages/register/register.module#RegisterModule'
  },
  {
    path: '', loadChildren: './pages/reset-password/reset-password.module#ResetPasswordModule',
  },
  {
    path: 'error', component: ErrorComponent
  },
  {
    path: '**', component: ErrorComponent
  }
];
