import { Component, OnDestroy, OnInit } from '@angular/core';
import { ResePwdServiceService } from '../rese-pwd-service.service';
import { ChangePassword } from '../model/ChangePassword';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { HostBinding } from '@angular/core';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  config:any
  @HostBinding('class') classes = 'auth-page app';
  backEndImage:string;
  BackendFinalurl:string;
  constructor(
    public resService: ResePwdServiceService,
    private toast: ToastrService,
    private appConfig: AppConfig
  ) { 
    // this.backEndImage=this.appConfig.imgbaseURL;
    this.backEndImage=this.appConfig.config.baseURLApi;
    this.BackendFinalurl=this.backEndImage.split('/')[2]; 
    this.config = this.appConfig.getConfig();
    
  }
  newpassword: string;
  confirmpassword: string;
  username: string = this.resService.username;

  ngOnInit() {
    if (!this.resService.isOTPverified()) {
      this.resService.navigateUrl('forget-password');
    } else {
      this.resService.msgError('');
    }
  }

  submit() {
    if (!this.isPasswordValid()) {
      if (!this.newpassword) {
        this.toast.error("Password field is empty");
      } else {
        this.toast.error("Passwords are not equal");
      }

    } else {
      this.resService.msgError('');
      const updatePwd = new ChangePassword(this.newpassword, this.username ,this.resService.ROTP);
      this.resService.resetPassword(updatePwd).subscribe(response => {
        if (response.responseCode === 200) {
          localStorage.clear();
          this.toast.success("Password Successfully chnaged")
          this.resService.navigateUrl('login');
        }
      })
    }
  }
  isPasswordValid() {
    return this.newpassword && this.newpassword === this.confirmpassword;
  }
  // @HostListener("window:unload", ['$event'])
  // handleUnload(event) {
  //   localStorage.removeItem("username")
  //   localStorage.removeItem("OTPVerified")
  // }

}
