import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

const BASE_API_URL = ''

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})

export class CustomerBasicDetailsService {
  constructor(private http: HttpClient) { }

  getTitleList() {
    return this.http.get(BASE_API_URL + 'subscriber/commonList/generic/title')
  }
  getCommonListtypeOfProperty(type): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/commonList/generic/' + type;
    return this.http.get<any>(get_url);
  }
  getDetailsByProperty(areaId:any, proprtyName:string): Observable<any> {
    const get_url = BASE_API_URL + 'lead/operation/getBuildingList/'+ areaId + '/' + proprtyName;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  saveBasicDetails(data){
    return this.http.post(BASE_API_URL+'lead/operation/lead-save',data)
  }
  checkUniqueCustomerByUsername(value): Observable<any> {
    const get_url = BASE_API_URL + 'lead/operation/checkUniqueForLead';
    let data: any = {
      "type": 'UserName',
      "value": value
    };
    return this.http.post<any>(get_url, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  getRequiredAllField(): Observable<any> {
    /**Only For Subscriber Create Put It Will Change per Requirement*/
    const get_url = BASE_API_URL + 'uiPageConfig/getUiConfig/registration';
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  MobileVerification(number) {
    let obj = {
      mobile: number.toString()
    }
    const post_url = BASE_API_URL + 'subscriber/genrateMobileOtpAtSave';
    return this.http.post<any>(post_url, obj)
  }
  getbasicdetails(obj) {
    const post_url = BASE_API_URL + 'lead/operation/getBasicDetailsMD5';
    return this.http.post<any>(post_url, obj)
  }
  getbasicdetails1(obj) {
    const post_url = BASE_API_URL + 'lead/operation/getBasicDetails';
    return this.http.post<any>(post_url, obj)
  }
  checkUniqueCustomerByMobile(value, custId): Observable<any> {
    const get_url = BASE_API_URL + 'lead/operation/checkUniqueForLead';
    let data: any = {
      "type": "mobileNo",
      "value": value,
      "id": custId
    };
    return this.http.post<any>(get_url, data, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  checkUniqueCustomerByEmail(value, custId): Observable<any> {
    const get_url = BASE_API_URL + 'lead/operation/checkUniqueForLead';
    let data: any = {
      "type": "email",
      "value": value,
      "id": custId
    };
    return this.http.post<any>(get_url, data, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  public getCommonListPropertyType(): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/commonList/generic/PropertyType';
    return this.http.get<any>(get_url);
  }
  getDetailsByPincode(parentid: any): Observable<any> {
    const get_url = BASE_API_URL + 'lead/operation/areaAndBuildingList/' + parentid;
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
}
