import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class TroubleTicketsDetailsService {
  constructor(private http: HttpClient) {}

  getTroubleTicketsDetailsList(custId : any): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/getCasesByCustomer/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  saveCasesRemarks(data: any): Observable<any> {
    const post_url = baseApiUrl + 'subscriber/updateCaseDetails/updateDetails';
    return this.http.post<any>(post_url, data).pipe(map(res => res),
    catchError((error: any) => {
        return throwError(error);
    }));
  }

  saveCases(data: any): Observable<any> {
    const post_url = baseApiUrl + 'subscriber/createCase';
    return this.http.post<any>(post_url, data,{ headers: httpOptions.headers }).pipe(map(res => res),
    catchError((error: any) => {
        return throwError(error);
    }));
  }


  getAllcaseReason(): Observable<any>{
    return this.http.get(baseApiUrl + 'subscriber/caseReason/isCustomerAllowed', { headers: httpOptions.headers });
  }

  getCommonListType(type): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/commonList/generic/'+type;
    return this.http.get<any>(get_url);
  }

  getCaseDetailsbyId(caseId : any): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/getCase/' + caseId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}