import { Component, OnInit } from '@angular/core';
import { ResePwdServiceService } from '../rese-pwd-service.service';
import { ToastrService } from 'ngx-toastr';
import { HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  userName: string;
  backEndImage:string;
  BackendFinalurl:string;
  config:any
  
  @HostBinding('class') classes = 'auth-page app';
  constructor(
    public restService: ResePwdServiceService,
    private toaster:ToastrService,
    private router:Router,
    private appConfig: AppConfig,
  ) {
    this.backEndImage=this.appConfig.config.baseURLApi;
    // this.backEndImage=this.appConfig.imgbaseURL;
    this.BackendFinalurl=this.backEndImage.split('/')[2]; 
    this.config = this.appConfig.getConfig();
   }

  ngOnInit() {
    this.restService.msgError('');
  }
  submit() {
    if (this.userName != null) {
      this.restService.getOTPservice({ username: this.userName }).subscribe(res => {

        if (res.responseCode == 200) {
          this.restService.setUser(this.userName);
          this.restService.navigateUrl('verification');
        } else if (res.responseCode == 404) {
           this.toaster.error(res.responseMessage);
          // this.restService.msgError("User not found");
        }
      })
    } else {
      this.toaster.error("Please provide username");
      // this.restService.msgError("Please provide username");
    }
  }
}
