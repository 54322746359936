import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SearchDataTypeEnum, SearchFilterConditionEnum, SearchFilterTypeValueEnum } from '../core/enum/searchType.enum';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(public datepipe: DatePipe) {}

  private subject = new Subject<any>();
  private filterSubject = new Subject<any>();

  setSearchSettings(obj: any) {
    this.subject.next(obj);
  }

  openSearchWithSettings(): Observable<any> {
    return this.subject.asObservable();
  }

  setFilterOptions(obj: any) {
    this.filterSubject.next(obj);
  }

  getFilterOptions(): Observable<any> {
    return this.filterSubject.asObservable();
  }

  clearFilterOptions(obj: any) {
    this.filterSubject.next(obj);
  }

  resetFilter(obj: any) {
    this.subject.next(obj);
  }

  getFilteredData(filterObj, filterData) {
    if (filterObj && filterData) {
      const filterFieldExists = filterObj.some((filter) => {
        return filter.filterColumn != '';
      });
      if (filterFieldExists && filterData) {
        let filteredData = filterData.filter((data) => {
          let conditionResult = true;
          filterObj.forEach((filter) => {
            if (!filter.filterCondition) {
              filter.filterCondition = SearchFilterConditionEnum.AND;
            }
            let filterValue = this.getFilterVal(filter);
            let dataValue = this.getDataVal(filter, data);
            switch (filter.filterCondition) {
              case SearchFilterConditionEnum.AND: {
                switch (filter.filterOperator) {
                  case SearchFilterTypeValueEnum.IS_EQUAL_TO: {
                    if (typeof filterValue == 'object') {
                      conditionResult = conditionResult && filterValue.indexOf(dataValue) >= 0;
                    } else {
                      conditionResult = conditionResult && filterValue == dataValue;
                    }
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO: {
                    if (typeof filterValue == 'object') {
                      conditionResult = conditionResult && filterValue.indexOf(dataValue) < 0;
                    } else {
                      conditionResult = conditionResult && filterValue != dataValue;
                    }
                    break;
                  }
                  case SearchFilterTypeValueEnum.GREATER_OR_EQUAL: {
                    conditionResult = conditionResult && dataValue >= filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.GREATER_THAN: {
                    conditionResult = conditionResult && dataValue > filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.LESS_OR_EQUAL: {
                    conditionResult = conditionResult && dataValue <= filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.LESS_THAN: {
                    conditionResult = conditionResult && dataValue < filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NULL: {
                    conditionResult = conditionResult && (dataValue == null || dataValue == '');
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NOT_NULL: {
                    conditionResult = conditionResult && (dataValue != null || dataValue != '');
                    break;
                  }
                  case SearchFilterTypeValueEnum.CONTAINS: {
                    conditionResult = conditionResult && dataValue.indexOf(filterValue) > -1;
                    break;
                  }
                  case SearchFilterTypeValueEnum.STARTS_WITH: {
                    conditionResult = conditionResult && dataValue.startsWith(filterValue);
                    break;
                  }
                }
                break;
              }
              case SearchFilterConditionEnum.OR: {
                switch (filter.filterOperator) {
                  case SearchFilterTypeValueEnum.IS_EQUAL_TO: {
                    conditionResult = conditionResult || dataValue == filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO: {
                    conditionResult = conditionResult || dataValue != filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.GREATER_OR_EQUAL: {
                    conditionResult = conditionResult || dataValue >= filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.GREATER_THAN: {
                    conditionResult = conditionResult || dataValue > filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.LESS_OR_EQUAL: {
                    conditionResult = conditionResult || dataValue <= filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.LESS_THAN: {
                    conditionResult = conditionResult || dataValue < filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NULL: {
                    conditionResult = conditionResult || dataValue == null || dataValue == '';
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NOT_NULL: {
                    conditionResult = conditionResult || dataValue != null || dataValue != '';
                    break;
                  }
                  case SearchFilterTypeValueEnum.CONTAINS: {
                    conditionResult = conditionResult || dataValue.indexOf(filterValue) > -1;
                    break;
                  }
                  case SearchFilterTypeValueEnum.STARTS_WITH: {
                    conditionResult = conditionResult || dataValue.startsWith(filterValue);
                    break;
                  }
                }
                break;
              }
            }
          });
          return conditionResult;
        });
        return filteredData;
      }
      return filterData;
    }
    return filterData;
  }

  getFilterVal(filter) {
    // SearchDataTypeEnum.DATE
    //             ? new Date(filter.filterValue).setHours(0, 0, 0, 0)
    //             : filter.filterDataType == SearchDataTypeEnum.STRING && filter.filterValue
    //             ? filter.filterValue.toLowerCase()
    //             : filter.filterValue;
    if(filter.filterDataType == SearchDataTypeEnum.DATE){
      return new Date(filter.filterValue).setHours(0, 0, 0, 0)
    }else{
      if(filter.filterDataType == SearchDataTypeEnum.STRING && filter.filterValue){
        if(typeof filter.filterValue == 'number'){
          return filter.filterValue.toString().trim();
        }
        return (filter.filterValue.toLowerCase()).trim();
      }else{
        return filter.filterValue;
      }
    }
  }

  getDataVal(filter, data) {
    // filter.filterDataType == SearchDataTypeEnum.DATE
    // ? new Date(data[filter.filterColumn]).setHours(0, 0, 0, 0)
    // : filter.filterDataType == SearchDataTypeEnum.STRING && filter.filterValue
    // ? (data[filter.filterColumn].toLowerCase()).trim()
    // : (data[filter.filterColumn]).trim();
    if(filter.filterDataType == SearchDataTypeEnum.DATE){
      return new Date(data[filter.filterColumn]).setHours(0, 0, 0, 0);
    }else{
      if(filter.filterDataType == SearchDataTypeEnum.STRING && filter.filterValue){
        if(typeof data[filter.filterColumn] == 'number'){
          return data[filter.filterColumn].toString().trim();
        }
        return (data[filter.filterColumn].toLowerCase()).trim();
      }else{
         return data[filter.filterColumn];
      }
    }
  }
  
  private getFilterResultByType(filter, conditionResult, filterType, data) {
    if (filterType == SearchFilterConditionEnum.AND) {
      return conditionResult && data[filter.filterColumn] == filter.filterValue;
    } else {
      return conditionResult || data[filter.filterColumn] != filter.filterValue;
    }
  }

  private customDateParser(dateStr: string): string {
    // 2018-01-01T12:12:12.123456; - converting valid date format like this

    let date = dateStr.substring(0, 10);
    let time = dateStr.substring(11, 19);
    let millisecond = dateStr.substring(20);

    let validDate = date + 'T' + time + '.' + millisecond;
    //console.log(validDate);
    return validDate;
  }
}
