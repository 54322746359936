import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationComponent } from './verification/verification.component';
import { RouterModule } from '@angular/router';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NewWidgetModule } from '../../layout/new-widget/widget.module';
import { AlertModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../layout/shared.module';

const route = [
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'verification', component: VerificationComponent },
  { path: 'change-password', component: ChangePasswordComponent }
]

@NgModule({
  declarations: [VerificationComponent, ForgetPasswordComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(route),
    NewWidgetModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
  ],
  exports:[VerificationComponent]
})
export class ResetPasswordModule { }
