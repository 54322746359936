export enum SearchDataTypeEnum {
  STRING = 'string',
  DATE = 'date',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
}

export enum SearchControlTypeEnum {
  TEXT = 'text',
  DATE = 'date',
  NUMBER = 'number',
  RADIO = 'radio',
  SELECT = 'select',
}

export enum SearchBooleanTypeEnum {
  YES = 'Y',
  NO = 'N',
  ZERO = '0',
  ONE = '1',
  TRUE = 'true',
  FALSE = 'false',
  ACTIVE = 'A',
  INACTIVE = 'I',
}

export enum SearchFilterTypeLabelEnum {
  IS_EQUAL_TO = 'Equal To',
  IS_NOT_EQUAL_TO = 'Not Equal',
  GREATER_OR_EQUAL = 'Greater Than Equal To',
  GREATER_THAN = 'Greater Than',
  LESS_OR_EQUAL = 'Less Than Equal To',
  LESS_THAN = 'Less Than',
  IS_NULL = 'Null',
  IS_NOT_NULL = 'Not Null',
  CONTAINS = 'Contains',
  // NOT_CONTAINS = 'Not Contains',
  STARTS_WITH = 'Starts With',
  // LIKE = 'Like',
  // ENDS_WITH = 'Ends With',
}

export enum SearchFilterTypeValueEnum {
  IS_EQUAL_TO = '=',
  IS_NOT_EQUAL_TO = '!=',
  GREATER_OR_EQUAL = '>=',
  GREATER_THAN = '>',
  LESS_OR_EQUAL = '<=',
  LESS_THAN = '<',
  IS_NULL = 'null',
  IS_NOT_NULL = '!null',
  CONTAINS = 'contains',
  // NOT_CONTAINS = 'notContains',
  STARTS_WITH = 'startsWith',
  // ENDS_WITH = 'endsWith',
  // LIKE = 'like',
}

export enum SearchFilterConditionEnum {
  AND = 'and',
  OR = 'or',
}
