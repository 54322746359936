import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as myGlobals from '../../core/modal/global';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const baseApiUrl = '';
@Injectable({
  providedIn: 'root'
})
export class CustPaymentService {

  purchaseInfo = [];
  partnerId : number;
  planName : string;
  planOfferPrice : 0;
  planTotalPrice : 0;
  leadCustId : number;
  leadMD5Id : string;
  constructor( private http: HttpClient) { 
  }
  

  insertPlaceOrder(placeOrder: any): Observable<any> {
    // const post_url = baseApiUrl + 'order/place';
     return this.http.post<any>('lead/operation/order/place', placeOrder, httpOptions).pipe(
       map(data => data),
       catchError((error: any) => {
           return throwError(error);
       })
     );
  }

  public getMd5IdData(token){
  const get_url=baseApiUrl+"lead/operation/getLeadPurchaseDetails/" + token;
  return this.http.get<any>(get_url);
  }

  public getHardwareCharges(){
    const get_url=baseApiUrl+"lead/operation/charge/ByCategory/hardware";
    return this.http.get<any>(get_url);
  }
  getPaymentGatewayByPartner(partnerId){
    const get_url=baseApiUrl+'subscriber/getPartnerPGConfigByPartnerId/'+partnerId;
    return this.http.get<any>(get_url);
  }

  place(id: any): Observable<any> {
    const post_url = baseApiUrl + 'lead/operation/getBasicDetails';
    return this.http.post<any>(post_url, { id }, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
  }

  checkUniqueCustomerByUsername(value): Observable<any> {
    const get_url = baseApiUrl + 'lead/operation/checkUniqueForLead';
    let data:any = {
      "type": "username",
      "value": value.value,
      "leadMD5Id":value.leadMD5Id
    };
    return this.http.post<any>(get_url,data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  saveuserName(name){
    const get_url = baseApiUrl + 'lead/operation/createLeadUsername';
    return this.http.post<any>(get_url,name,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

}
