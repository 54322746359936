import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../components/loading/loading.component';
import { PaginationComponent } from '../components/pagination/pagination.component';
import { GridComponent } from '../components/grid/grid.component';
import { FormsModule } from '@angular/forms';
import { NeoGridComponent } from '../components/neo-grid/neo-grid.component';
import { NeoTableLibModule } from 'n-neo-table-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChildLoadingComponent } from '../components/child-loading/child-loading.component';
@NgModule({
  declarations: [LoadingComponent, PaginationComponent, GridComponent, NeoGridComponent,ChildLoadingComponent],
  exports: [LoadingComponent, PaginationComponent, GridComponent, NeoGridComponent,ChildLoadingComponent],
  imports: [CommonModule, FormsModule, NeoTableLibModule, NgbModule],
})
export class SharedModule {}
