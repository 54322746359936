import {AppConfig} from '../../app.config';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Injectable} from '@angular/core';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../../auth/token-storage-service';

const jwt = new JwtHelperService();
const BASE_API_URL = '';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class LoginService {
  config: any;
  _isFetching: boolean = false;
  addressLevelList : any = [];
  _errorMessage: string = '';

  constructor(
    appConfig: AppConfig,
    private http: HttpClient,
    private htttp: Http,
    private tokenStorageService:TokenStorageService,
    private router: Router,
    private tostr:ToastrService
  ) {
    this.config = appConfig.getConfig();
  }

  get isFetching() {
    return this._isFetching;
  }

  set isFetching(val: boolean) {
    this._isFetching = val;
  }

  get errorMessage() {
    return this._errorMessage;
  }

  set errorMessage(val: string) {
    this._errorMessage = val;
  }
  public async getLevelOfAddress() {
    const get_url = BASE_API_URL + "lead/operation/addressLevel";
    try {
      const res: any = await this.http
        .get(get_url, { headers: httpOptions.headers })
        .toPromise();
      this.addressLevelList = res.dataList;
      this.tokenStorageService.setAddressLevel(this.addressLevelList);
      return this.addressLevelList;
    } catch (error) {
      this.tostr.error("Problem in getting address hierarchy");
      throw error;
    }
  }

  isAuthenticated() {
    const token = localStorage.getItem('custAccessToken');
    if (token) {
      return true
    }  else {
      return false;
    }
    // let data = null;
    //
    // // We check if app runs with backend mode
    // if (!this.config.isBackend && token) return true;
    // if (!token) return;
    // const date = new Date().getTime() / 1000;
    // try {
    // data = jwt.decodeToken(token);
    // } catch(e) {
    //   this.router.navigate(['/login']);
    // }
    // if (!data) return;
    // return date < data.exp;
  }


  loginUser(creds) {
    // We check if app runs with backend mode
    if (!this.config.isBackend) {
      this.receiveToken('accessToken');
    } else {
      this.requestLogin();
      if (creds.social) {
        // tslint:disable-next-line
        window.location.href = this.config.baseURLApi + '/user/signin/' + creds.social + (process.env.NODE_ENV === 'production' ? '?app=sing-app/angular' : '');
      } else if (creds.email.length > 0 && creds.password.length > 0) {
        this.http.post('/user/signin/local', creds).subscribe((res: any) => {
          const token = res.token;
          this.receiveToken(token);
        }, err => {
          this.loginError('Something was wrong. Try again');
        });

      } else {
        this.loginError('Something was wrong. Try again');
      }
    }
  }

  receiveToken(token) {
    let user: any = {};
    // We check if app runs with backend mode
    if (this.config.isBackend) {
      user = jwt.decodeToken(token).user;
      delete user.id;
    } else {
      user = {
        email: this.config.auth.email
      };
    }

    localStorage.setItem('custAccessToken', token);
    localStorage.setItem('custUser', JSON.stringify(user));
    this.receiveLogin();
  }

  logoutUser() {
    localStorage.removeItem('custAccessToken');
    localStorage.removeItem('custUser');
    localStorage.removeItem('custUserId');
    localStorage.removeItem('currentBalance');
    localStorage.removeItem('custPartnerId');
    document.cookie = 'custAccessToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.router.navigate(['/login']);
  }

  loginError(payload) {
    this.isFetching = false;
    this.errorMessage = payload;
  }

  receiveLogin() {
    this.isFetching = false;
    this.errorMessage = '';
    this.router.navigate(['/']);
  }

  requestLogin() {
    this.isFetching = true;
  }

  getVersionFromServer(){
    //console.log(location.origin);
    let _jsonURL = location.origin + "/assets/version.json";
      return this.htttp.get(_jsonURL);
   }

   getAllConfig(partnerId:number):Observable<any>{
    const post_url = BASE_API_URL + 'subscriber/getConfigurationByPartner/'+partnerId;
    return this.http.get<any>(post_url,
        ).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }
}
