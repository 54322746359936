import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const TOKEN_KEY = "custAccessToken"
const USERNAME_KEY = "custAuthUsername";
const AUTHORITIES_KEY = "custAuthAuthorities"
const USER_ID = "custUserId"
const ACTIVE_USER = "activeUser"
const PARTNER_ID = "custPartnerId"
const CURRENT_BALANCE = "currentBalance"
const CLASSICAL_VIEW = "isclassicalView"
const ADDRESS_LEVEL_LIST='addressLevelList'

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    private userCurrentBalance$: BehaviorSubject<any> = new BehaviorSubject(null);
    private userName$: BehaviorSubject<any> = new BehaviorSubject(null);
    private partnerId$: BehaviorSubject<any> = new BehaviorSubject(null);

    private roles: Array<string> = [];
    constructor() { }

    signOut() {
        this.removeAllCookies();
        window.localStorage.clear();
    }
    public setAddressLevel(levelList){
        window.localStorage.setItem(ADDRESS_LEVEL_LIST, JSON.stringify(levelList));
      }
    public getAddressLevel() {
        return JSON.parse(window.localStorage.getItem(ADDRESS_LEVEL_LIST));
    }

    // public setUserCurrentBalance(currentBalance){
    //    // window.localStorage.removeItem(CURRENT_BALANCE);
    //     window.localStorage.setItem(CURRENT_BALANCE, currentBalance);
    // }

    // public removeUserCurrentBalance(){
    //     window.localStorage.removeItem(CURRENT_BALANCE);
    //  }

    // public getUserCurrentBalance(){
    //     return window.localStorage.getItem(CURRENT_BALANCE);
    // }

    public saveToken(token: string, tokenExpiry: string) {
        this.removeCookie(TOKEN_KEY);
        this.setCookie(TOKEN_KEY, token, tokenExpiry);
        window.localStorage.setItem(TOKEN_KEY, token);
    }

    public getToken(): string {
        return this.getCookie(TOKEN_KEY);
        return window.localStorage.getItem(TOKEN_KEY);
    }

    public saveUserName(userName: string) {
        window.localStorage.removeItem(USERNAME_KEY);
        window.localStorage.setItem(USERNAME_KEY, userName);
    }

    public saveUserId(userId: string) {
        //window.localStorage.removeItem(USER_ID);
        window.localStorage.setItem(USER_ID, userId);
    }

    public saveClassicalView(classicalView: string) {
        window.localStorage.setItem(CLASSICAL_VIEW, classicalView);
    }


    public saveActiveUser(userId: string) {
        window.localStorage.removeItem(ACTIVE_USER);
        window.localStorage.setItem(ACTIVE_USER, userId);
        let tableObj = {};
        tableObj['username'] = userId;
        localStorage.setItem(ACTIVE_USER, JSON.stringify(tableObj));
    }

    public getLoggedinUserId() {
        return Number(window.localStorage.getItem(USER_ID));
    }

    public savePartnerId(partnerId: string) {
        window.localStorage.removeItem(PARTNER_ID);
        window.localStorage.setItem(PARTNER_ID, partnerId);
    }

    public getUserName(): string {
        return window.localStorage.getItem(USERNAME_KEY);
    }

    public getClassicalView(): string {
        return window.localStorage.getItem(CLASSICAL_VIEW);
    }

    setCookie(name, value, seconds) {
        var expires = "";
        if (seconds) {
            var date = new Date();
            date.setSeconds(date.getSeconds() + seconds);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    removeCookie(name) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
    removeAllCookies() {
        var res = document.cookie;
        var multiple = res.split(";");
        for (var i = 0; i < multiple.length; i++) {
            var key = multiple[i].split("=");
            this.removeCookie(key[0]);
        }
    }
    public getAuthorities(): string[] {
        this.roles = [];

        if (window.localStorage.getItem(AUTHORITIES_KEY) == null) {
            return this.roles;
        }

        JSON.parse(window.localStorage.getItem(AUTHORITIES_KEY)).forEach(authority => {
            this.roles.push(authority.authority);
        });

        return this.roles;
    }

    getUserCurrentWalletBalance(): Observable<any> {
        return this.userCurrentBalance$.asObservable();
    }

    setUserCurrentWalletBalance(userCurrentBalance: any) {
        this.userCurrentBalance$.next(userCurrentBalance);
    }

    getUsername(): Observable<any> {
        return this.userName$.asObservable();
    }

    setUserName(userName: any) {
        this.userName$.next(userName);
    }

    getpartnerId(): Observable<any> {
        return this.partnerId$.asObservable();
    }

    setpartnerId(partnerid: any) {
        this.partnerId$.next(partnerid);
    }
}
